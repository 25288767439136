import "./portfolio-styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faGithub,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faCircleHalfStroke,
} from "@fortawesome/free-solid-svg-icons";

export default function Portfolio({
  toggleDarkMode,
  open,
  showSpeechBubble,
  setShowSpeechBubble,
}) {
  return (
    <>
      <section>
        <header>
          <div class="switch">
            <label class="theme-switch" for="checkbox">
              <input type="checkbox" id="checkbox" onClick={toggleDarkMode} />
              <div class="slider round"></div>
            </label>
            <FontAwesomeIcon class="switch-logo" icon={faCircleHalfStroke} />
          </div>
        </header>

        <section class="home">
          <div class="content">
            <h5>Hiya! I am...</h5>
            <h2>
              <span>Patrick Tejada</span>
            </h2>
            <h4>Software Engineer</h4>
            <p>
              I'm a creative engineer in love with building immersive
              experiences, a knack for problem solving, and a passion for tech.
              <br />
              <br />
              <br />
              Based in Los Angeles.
            </p>
            <div class="btn-group">
              <a href="/static/files/PatrickTejada_Resume.pdf" download>
                Download Resume
              </a>
            </div>
            <div class="social-icons">
              <a
                href="https://www.linkedin.com/in/patricktejada/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a href="https://github.com/pattytejada" target="_blank">
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a href="https://twitter.com/pattytcodes" target="_blank">
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
              <a href="mailto:patrick.tejada@gmail.com" class="email-button">
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </div>
          </div>

          <div class="content">
            <img
              class="profile-pic"
              src="/static/images/profile_pic.jpg"
              alt="Profile Picture"
              onMouseEnter={() => {
                if (open) {
                  setShowSpeechBubble(true);
                }
              }}
              onMouseLeave={() => {
                if (open) {
                  setShowSpeechBubble(false);
                }
              }}
            />
          </div>
        </section>
        <section class="projects">
          <h2>Projects</h2>
          <div class="project-list">
            <div
              class="project-item"
              onMouseEnter={() => {
                // if (open) {
                //   console.log("enter");
                // }
              }}
              onMouseLeave={() => {
                // if (open) {
                //   console.log("exit");
                // }
              }}
            >
              <h3>Plot-AI</h3>
              <p>
                Won 2nd place at the 2024 Ai2 Hackathon. AI assited, financial model
                builder for real estate and investment properties. Built using{" "}
                <strong> Next.js</strong>, <strong>TypeScript</strong>,{" "}
                <strong>Tailwind CSS</strong>, <strong>tRPC</strong> and{" "}
                <strong>PostgreSQL</strong>
              </p>
              <a
                href="https://github.com/ai-hackathon-team-23/plot-ai"
                target="_blank"
              >
                View Project
              </a>
            </div>
            <div
              class="project-item"
              onMouseEnter={() => {
                // if (open) {
                //   console.log("enter");
                // }
              }}
              onMouseLeave={() => {
                // if (open) {
                //   console.log("exit");
                // }
              }}
            >
              <h3>AutoBots</h3>
              <p>
                Design project that utilizes microcontrollers and machine
                learning to create self-autonomous vehicles. Built using{" "}
                <strong>TensorRT</strong>, <strong>Python</strong> and the{" "}
                <strong>NVIDIA Jetson Nano</strong>
              </p>
              <a href="https://github.com/pattytejada/AutoBots" target="_blank">
                View Project
              </a>
            </div>
            <div
              class="project-item"
              onMouseEnter={() => {
                // if (open) {
                //   console.log("enter");
                // }
              }}
              onMouseLeave={() => {
                // if (open) {
                //   console.log("exit");
                // }
              }}
            >
              <h3>Doggo Dash (coming soon...)</h3>
              <p>
                Interactive web game built with <strong>R3F</strong>{" "}
              </p>
              <a>
                View Project
              </a>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
