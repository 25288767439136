import * as THREE from "three";
import { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useSpring, animated } from "@react-spring/three";
import {
  Text,
  Html,
  ContactShadows,
  PresentationControls,
  Float,
  Environment,
  useGLTF,
  OrbitControls,
} from "@react-three/drei";
import Portfolio from "./portfolio/Portfolio.jsx";

function Model({ open, hidden, setHidden, hinge, ...props }) {
  const group = useRef();
  const houseRef = useRef()
  // Load model
  const { nodes, materials } = useGLTF("/static/models/mac-draco.glb");

  // Take care of cursor state on hover
  const [hovered, setHovered] = useState(false);

  const [showSpeechBubble, setShowSpeechBubble] = useState(false);

  useEffect(
    () => void (document.body.style.cursor = hovered ? "pointer" : "auto"),
    [hovered]
  );

  // Make it float in the air when it's opened
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.x = THREE.MathUtils.lerp(
      group.current.rotation.x,
      open ? Math.cos(t / 10) / 10 + 0.25 : 0,
      0.1
    );
    group.current.rotation.y = THREE.MathUtils.lerp(
      group.current.rotation.y,
      open ? Math.sin(t / 10) / 4 : 0,
      0.1
    );
    group.current.rotation.z = THREE.MathUtils.lerp(
      group.current.rotation.z,
      open ? Math.sin(t / 10) / 10 : 0,
      0.1
    );
    group.current.position.y = THREE.MathUtils.lerp(
      group.current.position.y,
      open ? (-2 + Math.sin(t)) / 3 : -4.3,
      0.1
    );

    // Rotate the models
    // houseRef.current.rotation.x = t/10
    // houseRef.current.rotation.y = t/10
    // houseRef.current.rotation.z = t/10
  });

  const rootDataset = document.documentElement.dataset;

  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode((s) => !s);
    const inDarkMode = rootDataset.theme === "dark";
    rootDataset.theme = inDarkMode ? "" : "dark";
  };

  const [blink, setBlink] = useState(false);
  const [noInput, setNoInput] = useState(false);

  const [textOpacity, setTextOpacity] = useState(0);
  const [increaseOpacity, setIncreaseOpacity] = useState(false);

  useEffect(() => {
    if (!open && noInput) {
      const interval = setInterval(() => {
        setBlink(blink ? false : true);

        if (textOpacity >= 1) {
          setIncreaseOpacity(false);
        } else if (textOpacity <= 0) {
          setIncreaseOpacity(true);
        }

        if (increaseOpacity) {
          setTextOpacity(textOpacity + 0.05);
        } else {
          setTextOpacity(textOpacity - 0.05);
        }
      }, 50);

      //Clearing the interval
      return () => clearInterval(interval);
    } else {
      setTextOpacity(0);
      setNoInput(false);
    }
  }, [blink, open, noInput]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Check if the state is still the same after 5 seconds
      if (!open) {
        setNoInput(true);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [noInput, open]);

  return (
    <>
      <Text
        font="./fonts/Array-Regular.woff"
        fontSize={1}
        position={[0, -1, 0]}
        rotation-y={Math.PI * 180}
        maxWidth={10}
        color={"white"}
        visible={hidden}
        fillOpacity={textOpacity}
      >
        Click to Open
      </Text>

      <group
        ref={group}
        {...props}
        onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
        onPointerOut={(e) => setHovered(false)}
        dispose={null}
      >
        <animated.group rotation-x={hinge} position={[0, -0.04, 0.41]}>
          <group position={[0, 2.96, -0.13]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              material={materials.aluminium}
              geometry={nodes["Cube008"].geometry}
            />
            <mesh
              material={materials["matte.001"]}
              geometry={nodes["Cube008_1"].geometry}
            />
            <mesh
              material={materials["matte.001"]}
              geometry={nodes["Cube008_2"].geometry}
            />
          </group>

{/* <group ref={houseRef} position={[7, 0, 0]}>
<House></House>
</group>

<group position={[-7, 0, 0]}>
<Truck></Truck>
</group> */}

          <Html
            transform
            occlude
            onOcclude={setHidden}
            style={{
              transition: hidden ? "all 0s" : "all 0.5s",
              opacity: hidden ? 0 : 1,
              transform: `scale(${hidden ? 0.5 : 1})`,
            }}
            wrapperClass="htmlScreen"
            scale={3.255}
            distanceFactor={1}
            position={[0, 3.02, -0.091]}
            rotation-x={0}
          >
            <Portfolio
              toggleDarkMode={toggleDarkMode}
              open={open}
              showSpeechBubble={showSpeechBubble}
              setShowSpeechBubble={setShowSpeechBubble}
            />
          </Html>

          <Html
            occlude
            onOcclude={setShowSpeechBubble}
            style={{
              transition: !showSpeechBubble ? "all 0s" : "all 0.5s",
              opacity: !showSpeechBubble ? 0 : 1,
              transform: `scale(${!showSpeechBubble ? 0.5 : 1})`,
            }}
            position={[2.4, 4, 1.5]}
            wrapperClass="text-bubble"
          >
            This site was made with React Three Fiber,
            <br></br>
            Three.js, React Spring, HTML, & CSS 💻
          </Html>
        </animated.group>
        <mesh
          material={materials.keys}
          geometry={nodes.keyboard.geometry}
          position={[1.79, 0, 3.45]}
        />
        <group position={[0, -0.1, 3.39]}>
          <mesh
            material={materials.aluminium}
            geometry={nodes["Cube002"].geometry}
          />
          <mesh
            material={materials.trackpad}
            geometry={nodes["Cube002_1"].geometry}
          />
        </group>
        <mesh
          material={materials.touchbar}
          geometry={nodes.touchbar.geometry}
          position={[0, -0.03, 1.2]}
        />
      </group>
    </>
  );
}


function House(props) {
  const { scene } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/house-4/model.gltf')
  return <primitive object={scene} scale = {3} {...props} />
}


function Truck(props) {
  const { scene } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/truck/model.gltf')
  return <primitive object={scene} scale = {1} {...props} />
}

export default function Experience() {
  // This flag controls open state, alternates between true & false
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState();

  // We turn this into a spring animation that interpolates between 0 and 1
  const props = useSpring({ open: Number(open) });

  return (
    <>
      {/* <OrbitControls></OrbitControls> */}

      <animated.pointLight
        position={[10, 10, 10]}
        intensity={1.5}
        color={props.open.to([0, 1], ["#f0f0f0", "#d25578"])}
      />

      <Suspense fallback={null}>
        <group
          rotation={[0, Math.PI, 0]}
          onClick={(e) => (e.stopPropagation(), setOpen(!open))}
        >
          <Model
            open={open}
            hidden={hidden}
            setHidden={setHidden}
            hinge={props.open.to([0, 1], [1.575, -0.425])}
          />
        </group>
        <Environment preset="city" />
      </Suspense>

      <ContactShadows
        position={[0, -4.5, 0]}
        opacity={0.4}
        scale={20}
        blur={1.75}
        far={4.5}
      />
    </>
  );
}
